<script setup lang="ts">
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import type {PropType} from "vue";
import type {FrmForm, FrmFormField} from "~/integration/wordpress/types";
import {usePollForm} from "~/composables/Redmonkey";

const {setActive} = usePollForm();

const props = defineProps({
  formId: String as PropType<undefined | string | null>,
  form: Object as PropType<FrmForm | undefined>,
  isStepForm: {
    type: Boolean,
    default: false
  }
})

const invalid = ref(false);
const success = ref(false);
const form = ref(props.form);

async function onSubmit(values: object) {
  const formRequest = {
    frm_action: 'create',
    form_id: (form.value as FrmForm).info.id,
    form_key: (form.value as FrmForm).info.form_key,
    item_meta: values
  }

  await $fetch('/api/wordpress/submitFrmForm', {
    method: "post",
    body: {
      form: JSON.stringify(formRequest, null, 2)
    }
  })

  success.value = true;
  setTimeout(() => {
    success.value = false;
  }, 5000);

  setActive(false);
}

function onInvalidSubmit(data: object) {
  invalid.value = true;
  setTimeout(() => {
    invalid.value = false;
  }, 1000);
}

const schemaData = {} as {[key: string]: any;};

const formComponents = {} as {[key: string]: any;};

const getFieldValidation = (field: FrmFormField) => {
  let validate = Yup.object();

  const blank = field?.field_options?.blank?.replace('[field_name]', field?.name || field?.field_options?.placeholder) || 'Це поле обов\'язковe';
  const invalid = field?.field_options?.invalid?.replace('[field_name]', field?.name|| field?.field_options?.placeholder) || 'Формат невірний';

  switch (field.type) {
    case 'text': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'email': {
      validate = validate.shape({[field.id]: Yup.string().email(invalid)});
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'number': {
      validate = validate.shape({[field.id]: Yup.number()});
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'textarea': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextArea.vue'));
      break;
    }
    case 'checkbox': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Checkbox.vue'));
      break;
    }
    case 'radio': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Radio.vue'));
      break;
    }
    case 'select': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Select.vue'));
      break;
    }
    case 'url': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'phone': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'html': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Html.vue'));
      break;
    }
    case 'hidden': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/TextInput.vue'));
      break;
    }
    case 'submit': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Submit.vue'));
      break;
    }
    case 'step': {
      formComponents[field.id] = defineAsyncComponent(() => import('./type/Step.vue'));
      break;
    }
  }

  if (field?.required === '1' && field?.type !== 'submit' && field?.type !== 'step' && field?.type !== 'hidden') {
    validate = validate.shape({
      //@ts-ignore
      [field.id]: validate.fields[field.id] ? validate.fields[field.id].required(blank) : Yup.string().required(blank)
    });
  }

  return validate;
}

const setupValidation = (fields: [FrmFormField]) => {
  for (let f = 0; f < fields.length; f++) {
    const field = fields[f];
    schemaData[field.id] = getFieldValidation(field);

    if(field?.fields) {
      setupValidation(field?.fields);
    }
  }
}

if(form.value?.fields) {
  setupValidation(form.value?.fields || []);
}

const schemaFields = {} as {[key: string]: any;};

for(const s in schemaData) {
  if(schemaData.hasOwnProperty(s) && schemaData[s]?.fields?.[s]) {
    schemaFields[s] = schemaData[s].fields[s]
  }
}

const schema = Yup.object().shape(schemaFields);

const getFormInfoValue = (name: string) => {
  return (form.value as FrmForm)?.info?.[name];
}

const getFormOptionValue = (name: string) => {
  return getFormInfoValue('options')?.[name];
}

const formAttrs = {
  class: `${getFormInfoValue('form_key')} form-${getFormInfoValue('id')}${props.isStepForm ? ' step-form' : ''}`
};

const getSuccessMessage = () => {
  return (form.value as FrmForm)?.info?.options?.success_msg;
}

const activeStep = ref(form.value?.fields?.[0]?.id);

//@ts-ignore
const nextStep = ref(form.value?.fields?.[1]?.type === 'step' ? form.value?.fields?.[1].id : false);

const stepsData = ref({} as {[key: string]: object});

const setActiveStep = (stepId: string) => {
  activeStep.value = stepId;
  const currentStepIndex = form.value?.fields.findIndex((step) => step.id === stepId);
  if(currentStepIndex && currentStepIndex > -1) {
    const nextField = form.value?.fields?.[currentStepIndex + 1];
    nextStep.value = nextField?.type === 'step' ? nextField?.id : false;
  }
}

const setStepsData = (id: string, data: object) => {
  stepsData.value[id] = data;
}
</script>

<template>
  <div class="frm-form" v-bind="formAttrs" v-if="formId && form">
    <div class="form-success" v-if="success">
      <div class="success-message" v-html="getSuccessMessage()"></div>
    </div>
    <div class="form-content" v-else>
      <div class="form-titles" v-if="getFormOptionValue('show_title')|| getFormOptionValue('show_description') && !isStepForm">
        <h3 class="title" v-if="getFormOptionValue('show_title') && getFormInfoValue('name')">{{ getFormInfoValue('name') }}</h3>
        <div class="description" v-if="getFormOptionValue('show_description') && getFormInfoValue('description')" v-html="getFormInfoValue('description')"></div>
      </div>
      <div class="form-entries" :key="getFormInfoValue('name')">
        <Form
            @submit="onSubmit"
            :validation-schema="schema"
            @invalid-submit="onInvalidSubmit"
            :key="formId"
            :id="formId"
        >
          <template v-for="field in form.fields" :key="`${formId}-${field.field_key}`">
            <component
                v-if="formComponents[field.id]"
                :is="formComponents[field.id]"
                :name="field.id"
                :id="field.id"
                :label="field.name"
                :placeholder="field.field_options.placeholder"
                v-show="field.type !== 'hidden'"
                :field="field"
                :value="field.default_value"
                :active-step="activeStep"
                :set-active-step="setActiveStep"
                :next-step="nextStep"
                :steps-data="stepsData"
                :set-steps-data="setStepsData"
            />
          </template>
        </Form>
      </div>
    </div>
  </div>
</template>